<template>
<v-container>
   <v-row no-gutters>
     <v-col class="px-auto">
       <v-card flat color="transparent" >
        <v-card-title class="ml-4">
           Pages
        </v-card-title>
        <v-card-text class="">
          <v-btn text> Home</v-btn> <br>
          <v-btn text> Pricing</v-btn> <br>
          <v-btn text> About</v-btn> <br>
          <v-btn text> Contact Us</v-btn> <br>
        </v-card-text>

       </v-card>
     </v-col>

     <v-col class="px-auto">
       <v-card flat color="transparent">
        <v-card-title class="ml-4"> 
          Services
        </v-card-title>
        <v-card-text class="">
          <v-btn text> Writing</v-btn> <br>
          <v-btn text> Proof Reading</v-btn> <br>
          <v-btn text> Editing </v-btn> <br>
          <v-btn text> Rewriting</v-btn> <br>
        </v-card-text>

       </v-card>
     </v-col>

    <v-col class="px-auto">
       <v-card flat color="transparent">
        <v-card-title class=""> 
            Disclaimer          
        </v-card-title>
        <v-card-text class="">
          <p class="text-title"> 
            The Essay's obtained from EssayGenius are whould not be Used as final copy to your Academic assignments and papers. 
            They Should only be used as a guide on how to deliver and writer quality paper. EssayGenius does not condone academic mischief and Plagiarism.  
          </p>
        </v-card-text>

       </v-card>
     </v-col>
  </v-row>
</v-container>
</template>

<script>
import colorMxn from '@/mixins/colorMxn';

export default {
  name: 'Footer',
  mixins: [colorMxn],
  data() {
    return {
    }
  }
}
</script>